<!-- <div class="modal-header">
  <h4 class="modal-title"></h4>
<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div> -->

<div class="modal-body">
  <button type="button" class="btn-close float-end" aria-label="Close" (click)="activeModal.close()"></button>

  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <!-- Info tab-->
    <ng-container ngbNavItem>
      <a ngbNavLink>Info</a>

      <ng-template ngbNavContent class="mt-4">
        <ch-manual
          [page]="toolService.getManualPage(tool.name.id) | async"
          [showControls]="true"
          [addContainer]="false"></ch-manual>
      </ng-template>
    </ng-container>

    <!-- Source code tab-->
    <ng-container ngbNavItem>
      <a ngbNavLink>Source code</a>

      <ng-template ngbNavContent>
        <ch-tool-source [selectedTool]="tool"></ch-tool-source>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<!-- <div class="modal-footer">
  <div class="col px-0">
    <button type="button" class="btn btn-sm btn-secondary float-end" (click)="activeModal.close()">Close</button>
  </div>
</div> -->
