<div class="dataset-details">
  <div class="row">
    <div class="col">
      <table class="table table-sm text-sm align-top">
        <tbody>
          <tr>
            <td class="lighter">Created&nbsp;with:</td>
            <td class="width-max">
              {{ toolCategory }} / {{ toolName }}
              <!-- context dropdown -->
              <div ngbDropdown class="d-inline-block">
                <button
                  type="button"
                  class="btn btn-sm ms-1 btn-outline-dark no-caret-dropdown dropdown-menu-button"
                  id="viewDropdownMenuButton"
                  ngbDropdownToggle
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="Actions">
                  <!-- <i class="fa-solid fa-bars" aria-hidden="true"></i> -->
                  <i class="fas fa-lg fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="viewDropdownMenuButton">
                  <button ngbDropdownItem (click)="selectTool()" [class.disabled]="!tool">Select Tool</button>
                  <button ngbDropdownItem (click)="showJob()" [class.disabled]="!sourceJob">Show Job</button>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="sourceJob && sourceJob.parameters.length > 0">
            <td class="lighter">Parameters:</td>
            <td id="parameters-container">
              <ch-dataset-parameter-list
                *ngIf="sourceJob && sourceJob.parameters.length > 0"
                [parameters]="sourceJob.parameters"
                [parametersLimit]="parametersLimit"
                [tool]="tool"></ch-dataset-parameter-list>
            </td>
          </tr>

          <tr>
            <td class="lighter">Size:</td>
            <td class="width-max">{{ dataset.size | bytes : 0 }}</td>
          </tr>
          <tr>
            <td class="lighter">Date:</td>
            <td>{{ dataset.created | localDate }}</td>
          </tr>
          <tr>
            <td class="lighter">History:</td>
            <td>
              <ch-link-button class="no-underline-link-button text-sm" (click)="showHistory()">
                Show history
              </ch-link-button>
            </td>
          </tr>
          <tr>
            <td class="lighter">Notes:</td>
            <td>
              <textarea
                #notesArea
                [(ngModel)]="dataset.notes"
                class="dataset-notes"
                rows="6"
                placeholder="{{ notesPlaceholderInactive }} "
                (focus)="editNotes(notesInput)"
                (focusout)="saveNotes(dataset, notesInput)"
                #notesInput></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
