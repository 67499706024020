<h3 class="panel-title">Tools</h3>
<!-- module buttons -->
<div class="container tools-dropdown chipster-highlight" id="toolDropdown">
  <div class="row">
    <div class="col">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-sm"
          (click)="selectModule(module)"
          *ngFor="let module of modules | modulepipe: searchTool"
          [ngClass]="{ 'btn-primary': module === selectedModule, 'btn-light': module !== selectedModule }">
          {{ module.name }}
        </button>
      </div>
    </div>

    <div class="col">
      <ch-search-box
        #searchBox
        placeholder="Find tool"
        focusHotkey="t"
        focusHotkeyDescription="Find tool"
        (valueChange)="search($event)"
        (enterKey)="searchEnter()"></ch-search-box>
    </div>
  </div>

  <div class="row">
    <!--
    <div class="col">
      <h5>Tool</h5>
    </div>
  --></div>

  <div class="row tool-list-row">
    <div class="col">
      <ch-scroller>
        <div class="tool-list list-group-sm" id="category-tool-list">
          <div *ngFor="let category of selectedModule.categories | categorypipe: searchTool">
            <button
              type="button"
              class="list-group-item list-group-item-action tool-list-item"
              [ngClass]="{ active: category === selectedCategory }"
              (click)="selectCategory(category)">
              <ch-tool-list-item [color]="category.color" [categoryname]="category.name"></ch-tool-list-item>
            </button>

            <ch-scroller>
              <!-- fix missing toolSelection first-->
              <!-- <div class="tool-list list-group-sm" *ngIf="selectedCategory===category" id="tool-list">
                <button type="button" class="tool-item list-group-item list-group-item-action tool-list-item"
                  [ngClass]="{active: tool===toolSelection?.tool}" (click)="selectTool(tool)"
                  *ngFor="let tool of selectedCategory.tools | toolpipe:searchTool" id="tool-button-{{tool.id}}"
                  ngbPopover="{{tool.description}}" triggers="manual" container="body" placement="left" #p="ngbPopover"
                  (mouseenter)="tool.description && p.open()" (mouseleave)="tool.description && p.close()">
                  {{tool.name.displayName}}
                </button>

              </div> -->
            </ch-scroller>
          </div>
        </div>
      </ch-scroller>
    </div>

    <!--
    <div class="col">
      <ch-scroller>
        <div class="tool-list list-group-sm" *ngIf="selectedCategory" id="tool-list">
          <button type="button" class="list-group-item list-group-item-action tool-list-item" [ngClass]="{active: tool===toolSelection?.tool}"
            (click)="selectTool(tool)" *ngFor="let tool of selectedCategory.tools | toolpipe:searchTool" id="tool-button-{{tool.id}}"
            ngbPopover="{{tool.description}}" triggers="manual" container="body" placement="left" #p="ngbPopover" (mouseenter)="tool.description && p.open()"
            (mouseleave)="tool.description && p.close()">
            {{tool.name.displayName}}
          </button>
        </div>
      </ch-scroller>
    </div>
  --></div>
</div>
