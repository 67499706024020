<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <h5>Details</h5>
  <pre style="white-space: pre-wrap">{{ job.stateDetail }}</pre>

  <button class="btn btn-sm btn-secondary me-2" style="width: 11em" (click)="toggleScreenOutput()">
    {{ screenOutputButtonText }}
  </button>

  <button class="btn btn-sm btn-secondary" (click)="contactSupport()">Contact support</button>

  <div *ngIf="screenOutputVisible" class="mt-3">
    <h5>Screen output</h5>
    <pre style="white-space: pre-wrap">{{ job.screenOutput }}</pre>
  </div>
</div>
