<div class="modal-header">
  <h4 class="modal-title">Upload files</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <table class="table table-sm">
    <thead>
      <th style="width: 100%">File</th>
      <th style="width: 8rem; min-width: 7rem">Status</th>
      <th style="width: 8rem; min-width: 6rem">Size</th>
      <th style="width: 8rem; min-width: 7rem">Speed</th>
      <th style="width: 8rem; min-width: 6rem">Actions</th>
    </thead>
    <tbody>
      <tr *ngFor="let file of files">
        <td>
          {{ file.relativePath }}
        </td>
        <td>
          <ngb-progressbar
            *ngIf="!(file.error || file.isComplete())"
            [value]="file.progress() * 100"
            [max]="100"></ngb-progressbar>
          <span *ngIf="file.isComplete() && !file.error">
            <i class="fas fa-check-circle text-success" aria-hidden="true"></i>
          </span>
          <i *ngIf="file.error" class="fas fa-exclamation-circle text-danger" aria-hidden="true"></i>
        </td>
        <td style="white-space: nowrap">
          {{ file.size | bytes }}
        </td>
        <td style="white-space: nowrap">
          <span *ngIf="file.currentSpeed">{{ file.currentSpeed | bytes }}/s</span>
        </td>
        <td>
          <div>
            <button
              type="button"
              class="btn btn-outline-dark btn-no-border btn-sm"
              (click)="file.pause()"
              *ngIf="file.progress() != 0 && !file.paused && !file.isComplete()">
              <i class="fas fa-pause" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-no-border btn-sm"
              (click)="file.resume()"
              *ngIf="file.paused && !file.isComplete()">
              <i class="fas fa-play" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-no-border btn-sm float-end"
              (click)="cancelUpload(file)"
              *ngIf="!file.isComplete()">
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col">
      <!-- <span *ngIf="flow.getSize()" class="badge badge-info float-start">Total Size: {{flow.getSize() | bytes}}</span> -->
      <span *ngIf="flow.timeRemaining()" class="float-start">
        <i>Time remaining: {{ flow.timeRemaining() | secondspipe }}</i>
      </span>

      <div class="float-end text-nowrap">
        <button class="btn btn-outline-secondary btn-sm controlButton ms-1" (click)="flow.cancel()">
          <i class="fas fa-times" aria-hidden="true"></i>
          Cancel all
        </button>
        <button class="btn btn-outline-secondary btn-sm controlButton ms-1" (click)="flow.resume()">
          <i class="fas fa-play" aria-hidden="true"></i>
          Resume all
        </button>
        <button class="btn btn-outline-secondary btn-sm controlButton ms-1" (click)="flow.pause()">
          <i class="fas fa-pause" aria-hidden="true"></i>
          Pause all
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="col px-0">
    <!-- context dropdown -->
    <div ngbDropdown class="d-inline-block float-start">
      <button
        type="button"
        class="btn btn-sm btn-success"
        id="addFileDropdownMenuButton"
        ngbDropdownToggle
        aria-haspopup="true"
        aria-expanded="false"
        title="Actions">
        <i class="fas fa-upload" aria-hidden="true"></i>
        Add more files
      </button>
      <div ngbDropdownMenu aria-labelledby="addFileDropdownMenuButton">
        <button #uploadFilesButton ngbDropdownItem>Upload file(s)</button>
        <button #uploadFolderButton ngbDropdownItem>Upload folder</button>
      </div>
    </div>

    <button type="button" class="btn btn-sm btn-secondary float-end" (click)="activeModal.close()">Close</button>
  </div>
</div>
