<!-- #bee5eb #d1ecf1; #bee5eb #cce5ff #b8daff-->
<svg
  style="position: absolute; right: 0px; display: inline"
  class="p-0"
  width="35px"
  height="35px"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 100 100"
  enable-background="new 0 0 100 100"
  xml:space="preserve">
  <circle
    fill="none"
    stroke="#80bdff"
    stroke-width="10"
    stroke-miterlimit="15"
    stroke-dasharray="80,10"
    cx="50"
    cy="50"
    r="43">
    <animateTransform
      *ngIf="count > 0"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      dur="5s"
      from="0 50 50"
      to="360 50 50"
      repeatCount="indefinite" />
  </circle>
  <!-- <circle fill="none" stroke="#b8daff" stroke-width="4" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50"
              cy="50" r="35">
              <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50"
                repeatCount="indefinite" />
            </circle> -->
</svg>

<span
  style="font-family: monospace"
  class="text-primary position-absolute"
  [ngStyle]="{
    'right.px': offsetRight,
    'top.px': offsetTop,
    'font-size.px': fontSize
  }">
  <strong>{{ count }}</strong>
</span>
