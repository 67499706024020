<div class="modal-header">
  <h4 class="modal-title">Settings</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <h4 class="h4-xs">Session list mode</h4>

  <form class="px-2">
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        id="exampleRadios1"
        value="option1"
        (click)="setSessionListMode(SessionListMode.CLICK_TO_OPEN_HOVER_TO_PREVIEW)"
        [checked]="settingsService.sessionListMode$.getValue() === SessionListMode.CLICK_TO_OPEN_HOVER_TO_PREVIEW" />
      <label class="form-check-label" for="exampleRadios1">
        {{ SessionListMode.CLICK_TO_OPEN_HOVER_TO_PREVIEW }}
      </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        id="exampleRadios2"
        value="option2"
        (click)="setSessionListMode(SessionListMode.CLICK_TO_OPEN_BUTTON_TO_PREVIEW)"
        [checked]="settingsService.sessionListMode$.getValue() === SessionListMode.CLICK_TO_OPEN_BUTTON_TO_PREVIEW" />
      <label class="form-check-label" for="exampleRadios2">
        {{ SessionListMode.CLICK_TO_OPEN_BUTTON_TO_PREVIEW }}
      </label>
    </div>
    <div class="form-check disabled">
      <input
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        id="exampleRadios3"
        value="option3"
        (click)="setSessionListMode(SessionListMode.CLICK_TO_PREVIEW_BUTTON_TO_OPEN)"
        [checked]="settingsService.sessionListMode$.getValue() === SessionListMode.CLICK_TO_PREVIEW_BUTTON_TO_OPEN" />
      <label class="form-check-label" for="exampleRadios3">
        {{ SessionListMode.CLICK_TO_PREVIEW_BUTTON_TO_OPEN }}
      </label>
    </div>
  </form>

  <!-- <form class="px-2" style="margin: 5px; margin-top: 10px">
    <span class="switch switch-sm">
      <input type="checkbox" class="switch" id="switch-sm" (click)="showDataselectionTooltip()"
        [checked]="settingsService.showDatasetSelectionTooltip$.getValue()">
      <label for="switch-sm" class="h4-xs" style="margin-left: 8px">Show dataset selection tooltip</label>
    </span>
  </form> -->
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary float-end" (click)="activeModal.close()">Close</button>
</div>
