<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <pre class="mb-1">{{ text }}</pre>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="submit" class="btn btn-info" (click)="cancel()">Close</button>
</div>
