import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "ch-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.less"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AdminComponent {
  constructor() {}
}
