<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label>{{ message }}</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary float-end" (click)="cancel()">{{ cancelButtonText }}</button>
  <button type="submit" class="btn btn-info" (click)="save()" #submitButton>{{ okButtonText }}</button>
</div>
