<div [ngClass]="{ container: addContainer }">
  <div class="row">
    <div class="col">
      <div *ngIf="showControls">
        <button class="btn btn-secondary btn-sm float-end" (click)="openNewWindow()">Open in new tab</button>
      </div>

      <ch-static-html *ngIf="html" [html]="html"></ch-static-html>
    </div>
  </div>
</div>
