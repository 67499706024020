<div *ngIf="!state.isReady(); then statusTemplate"></div>

<div class="row">
  <div class="col-md-8">
    <div id="scatterplot"></div>
  </div>
  <div class="col-md-4" *ngIf="state.isReady()" id="setting">
    <label for="selectXaxis" class="text-sm">X-axis</label>
    <select
      class="form-control"
      id="selectXaxis"
      [(ngModel)]="selectedXAxisHeader"
      (ngModelChange)="setXAxisHeader($event)">
      <option *ngFor="let header of chipHeaders">{{ header }}</option>
    </select>

    <label for="selectYaxis" class="text-sm">Y-Axis</label>
    <select
      class="form-control"
      id="selectYaxis"
      [(ngModel)]="selectedYAxisHeader"
      (ngModelChange)="setYAxisHeader($event)">
      <option class="text-sm" *ngFor="let header of chipHeaders">{{ header }}</option>
    </select>
    <div id="selectedGeneList" *ngIf="selectedDataRows && selectedDataRows.length > 0">
      <p class="text-sm-center">{{ selectedDataRows.length }} Genes Selected</p>
      <div class="table table-sm">
        <tr *ngFor="let gene of selectedDataRows">
          <td class="text-sm">{{ gene.row[0] }}</td>
        </tr>
      </div>
      <div class="btn-group me-2" role="group">
        <button class="btn btn-info btn-sm" (click)="createDatasetFromSelected()">Create Dataset</button>
      </div>
      <div class="btn-group me-2" role="group">
        <button class="btn btn-info btn-sm" (click)="resetSelections()">Clear Selection</button>
      </div>
    </div>
  </div>
</div>

<ng-template #statusTemplate>
  <ch-status [state]="state" (buttonEvent)="showAnyway()"></ch-status>
</ng-template>
