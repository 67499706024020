<p *ngIf="!active">
  <i>No visualization available</i>
</p>
<div id="visTab">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="nav-tabs">
    <li [ngbNavItem]="getTabId('spreadsheet')" *ngIf="isTabVisible('spreadsheet')">
      <a ngbNavLink>Spreadsheet</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-spreadsheet-visualization
            [dataset]="selectionService.selectedDatasets[0]"
            [sessionData]="sessionData"></ch-spreadsheet-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('text')" *ngIf="isTabVisible('text')">
      <a ngbNavLink>Text</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-text-visualization
            [dataset]="selectionService.selectedDatasets[0]"
            [modalMode]="false"></ch-text-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('expressionprofile')" *ngIf="isTabVisible('expressionprofile')">
      <a ngbNavLink>Expression Profile</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-expression-profile [dataset]="selectionService.selectedDatasets[0]"></ch-expression-profile>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('image')" *ngIf="isTabVisible('image')">
      <a ngbNavLink>Image</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-image-visualization [dataset]="selectionService.selectedDatasets[0]"></ch-image-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('pdf')" *ngIf="isTabVisible('pdf')">
      <a ngbNavLink>
        <i class="far fa-file-pdf"></i>
        PDF
      </a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-pdf-visualization [dataset]="selectionService.selectedDatasets[0]"></ch-pdf-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('html')" *ngIf="isTabVisible('html')">
      <a ngbNavLink>HTML</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-htmlvisualization [dataset]="selectionService.selectedDatasets[0]"></ch-htmlvisualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('scatterplot')" *ngIf="isTabVisible('scatterplot')">
      <a ngbNavLink>Scatter Plot</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-scatter-plot [dataset]="selectionService.selectedDatasets[0]"></ch-scatter-plot>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('volcanoplot')" *ngIf="isTabVisible('volcanoplot')">
      <a ngbNavLink>Volcano Plot</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-volcano-plot [dataset]="selectionService.selectedDatasets[0]"></ch-volcano-plot>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('phenodata')" *ngIf="isTabVisible('phenodata')">
      <a ngbNavLink>Phenodata</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-phenodata-visualization
            [dataset]="selectionService.selectedDatasets[0]"
            [datasetsMap]="sessionData.datasetsMap"></ch-phenodata-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('new-tab')" *ngIf="isTabVisible('new-tab')">
      <a ngbNavLink>
        <!-- <i class="fas fa-external-link-alt"></i> -->
        Open in New Tab
      </a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-new-tab-visualization [dataset]="selectionService.selectedDatasets[0]"></ch-new-tab-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('details')" *ngIf="isTabVisible('details')">
      <a ngbNavLink>Details</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-details-visualization
            [datasets]="selectionService.selectedDatasets"
            [sessionData]="sessionData"
            [tools]="tools"></ch-details-visualization>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="getTabId('venn')" *ngIf="isTabVisible('venn')">
      <a ngbNavLink>Venn Diagram</a>
      <ng-template ngbNavContent>
        <div class="visualization-panel">
          <ch-venn-diagram [selectedDatasets]="selectionService.selectedDatasets"></ch-venn-diagram>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>
