<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="value">{{ description }}</label>
    <input
      id="value"
      #valueInput
      type="text"
      class="form-control"
      [(ngModel)]="value"
      [placeholder]="placeHolder"
      (keydown.enter)="save($event)" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary float-end" (click)="cancel()">Cancel</button>
  <button type="submit" [disabled]="!value" class="btn btn-info" (click)="save($event)">{{ buttonText }}</button>
</div>
