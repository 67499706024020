<div class="modal-header">
  <h4 class="modal-title">Contact support</h4>
</div>
<div class="modal-body">
  <form [formGroup]="supportForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="messageTextarea">Message</label>
      <textarea
        rows="3"
        formControlName="message"
        required
        #messageTextarea
        id="messageTextarea"
        class="form-control"></textarea>
      <div [class.hidden]="!isValidationError(message)" class="invalid-feedback">
        <div *ngIf="message.errors && message.errors['required']">Please describe what happened</div>
      </div>
    </div>

    <div class="mt-4">
      <label>Attach session</label>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          value="yes"
          formControlName="attach"
          [required]="session != null"
          id="attachYesInput" />
        <label class="form-check-label" for="attachYesInput">
          Attach a copy of your last session
          <em>
            <b>{{ session?.name || "(not available)" }}</b>
          </em>
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          value="no"
          formControlName="attach"
          [required]="session != null"
          id="attachNoInput" />
        <label class="form-check-label" for="attachNoInput">Don't attach the session</label>
      </div>
      <div [class.hidden]="!isValidationError(attach)" class="invalid-feedback">
        <div *ngIf="attach.errors && attach.errors['required']">Please choose</div>
      </div>
    </div>

    <div class="form-group mt-4">
      <label for="emailInput">Your email address</label>
      <input
        type="email"
        placeholder="Enter email"
        formControlName="email"
        required
        id="emailInput"
        aria-describedby="emailHelp"
        class="form-control" />
      <small class="form-text text-muted" id="emailHelp">Support personnel will use this address to contact you.</small>
      <small class="form-text text-muted" *ngIf="isVerifiedEmail">
        This email address was received from your login details. If it's not correct, please contact the organization
        that provided your login credentials to update it.
      </small>

      <div [class.hidden]="!isValidationError(email)" class="invalid-feedback">
        <div *ngIf="email.errors && email.errors['required']">Please enter your email address</div>
        <div *ngIf="email.errors && email.errors['email']">Please enter a valid email address</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="cancel()">Cancel</button>
  <button type="submit" class="btn btn-sm btn-info pull right" [disabled]="!supportForm.valid" (click)="onSubmit()">
    Send
  </button>
</div>
