<div *ngIf="state.isReady(); else statusTemplate">
  <div class="scroller">
    <iframe
      #htmlframe
      *ngIf="src"
      width="100%"
      [src]="wrapperUrl + '?location=' + src | trustedresource"
      scrolling="no"
      frameborder="0"
      (load)="run(htmlframe)"></iframe>
  </div>
</div>

<ng-template #statusTemplate>
  <ch-status [state]="state"></ch-status>
</ng-template>
