<!-- no selection, show something -->
<div *ngIf="!(showTool || showFile || showJob)">
  <h3 class="panel-title">Selection</h3>
  <div class="chipster-highlight">
    <i>No file, tool or job selected</i>
  </div>
</div>

<!-- tool -->
<ch-tool-details
  *ngIf="showTool || settingsService.splitSelectionPanel$.getValue()"
  [sessionData]="sessionData"
  [selectedDatasets]="selectionService.selectedDatasets"></ch-tool-details>

<!-- job -->
<ch-job *ngIf="showJob" [sessionData]="sessionData"></ch-job>

<!-- file -->
<ch-selected-files
  *ngIf="showFile || settingsService.splitSelectionPanel$.getValue()"
  [dataset]="selectionService.selectedDatasets[0]"
  [sessionData]="sessionData"
  [tools]="tools"></ch-selected-files>
