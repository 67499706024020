<table class="table">
  <thead>
    <tr>
      <th scope="col">Auth</th>
      <th scope="col">Username</th>
      <th scope="col">Organization</th>
      <th scope="col">Name</th>
      <th scope="col">Mail</th>
      <th scope="col">Terms of Use accepted</th>
      <th scope="col">Created</th>
      <th scope="col">Modified</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <td>{{ user.auth }}</td>
      <td>{{ user.username }}</td>
      <td>{{ user.organization }}</td>
      <td>{{ user.name }}</td>
      <td>{{ user.mail }}</td>
      <td title="version: {{ user.termsVersion }}">{{ user.termsAccepted | localDate }}</td>
      <td>{{ user.created | localDate }}</td>
      <td>{{ user.modified | localDate }}</td>
    </tr>
  </tbody>
</table>
