<div *ngIf="state !== ComponentState.READY && state !== ComponentState.NOT_FOUND" class="container-fluid">
  {{ state }}
</div>

<div *ngIf="state === ComponentState.NOT_FOUND" class="container-fluid">
  <h3 class="h3-xs">Session not found</h3>
  <p>
    Go to
    <a [routerLink]="routeService.getRouterLinkSessions()">Sessions</a>
    to open an existing session or to create a new one.
  </p>
</div>

<div *ngIf="state === ComponentState.READY" class="session-view container-fluid">
  <as-split>
    <!-- split 1 -->
    <as-split-area [size]="split1Size" class="d-flex flex-column">
      <!-- workflow and files -->
      <ch-session-panel
        [sessionData]="sessionData"
        [modulesMap]="modulesMap"
        [tools]="tools"
        class="d-flex flex-column flex-grow-1"></ch-session-panel>
    </as-split-area>

    <!-- split 2-->
    <as-split-area id="myDiv" [size]="split2Size" class="scrollable">
      <div *ngIf="!split3Visible; else selectionPanel">
        <div class="row">
          <div class="col">
            <ch-tools
              [sessionData]="sessionData"
              [toolsArray]="tools"
              [modulesArray]="modules"
              [modulesMap]="modulesMap"></ch-tools>
          </div>
        </div>

        <div class="row" id="visTab">
          <div class="col">
            <!-- TODO refactor logic choosing whether to show job or file or session info -->

            <ch-session-details
              *ngIf="selectionService.selectedDatasets.length < 1"
              [session]="sessionData.session"
              [sessionData]="sessionData"
              (deleteSession)="onDeleteSession()"></ch-session-details>

            <ch-selected-files
              *ngIf="selectionService.selectedDatasets.length > 0"
              [dataset]="selectionService.selectedDatasets[0]"
              [sessionData]="sessionData"
              [tools]="tools"
              (doScrollFix)="doScrollFix()"></ch-selected-files>
          </div>
        </div>
      </div>

      <ng-template #selectionPanel>
        <ch-selection-panel [sessionData]="sessionData" [tools]="tools"></ch-selection-panel>
      </ng-template>
    </as-split-area>

    <!-- split 3-->
    <as-split-area *ngIf="split3Visible" [size]="split3Size" class="split-3 scrollable">
      <div class="row">
        <div class="col">
          <ch-tool-list-accordion
            [sessionData]="sessionData"
            [toolsArray]="tools"
            [modulesArray]="modules"></ch-tool-list-accordion>
        </div>
      </div>
    </as-split-area>
  </as-split>
</div>
