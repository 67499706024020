<h3 class="panel-title">Session Info</h3>

<div class="chipster-highlight">
  <div class="mb-2">
    <span class="h6">{{ session.name }}</span>

    <!-- context dropdown -->
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-sm ms-1 btn-outline-dark no-caret-dropdown dropdown-menu-button"
        id="sessionDropdownMenuButton"
        ngbDropdownToggle
        aria-haspopup="true"
        aria-expanded="false"
        title="Actions">
        <!-- <i class="fa-solid fa-bars" aria-hidden="true"></i> -->
        <i class="fas fa-lg fa-ellipsis-h" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="sessionDropdownMenuButton">
        <button ngbDropdownItem (click)="renameSessionModal()">Rename&hellip;</button>
        <button ngbDropdownItem (click)="notesModal()">Edit Notes&hellip;</button>
        <button ngbDropdownItem (click)="sharingModal()">Share&hellip;</button>
        <button ngbDropdownItem (click)="duplicateModal()">Save a Copy&hellip;</button>
        <button ngbDropdownItem (click)="downloadSession()">Download&hellip;</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem (click)="removeSessionModal()">Delete&hellip;</button>
      </div>
    </div>
  </div>

  <div class="border p-3" style="background-color: white">
    <table class="table table-sm text-sm">
      <tr>
        <td class="lighter">Created:</td>
        <td width="100%">{{ session.created | localDate }}</td>
      </tr>
      <tr>
        <td class="lighter">Size:</td>
        <td width="100%">{{ getSessionSize() | bytes : 0 }}</td>
      </tr>
      <tr>
        <td class="lighter">Notes:</td>
        <td width="100%">
          <p class="mb-1" *ngFor="let line of session.notes?.split('\n')">
            {{ line }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</div>
