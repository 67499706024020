<div class="modal-header">
  <h4 class="modal-title">{{ dataset.name }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <div class="visualization-modal-content">
    <ch-text-visualization
      *ngIf="visualizationId === 'text'"
      [dataset]="dataset"
      [showFullData]="true"
      [modalMode]="true"></ch-text-visualization>

    <ch-spreadsheet-visualization
      class="modal-spreadsheet"
      *ngIf="visualizationId === 'spreadsheet'"
      [dataset]="dataset"
      [sessionData]="sessionData"
      [modalMode]="true"></ch-spreadsheet-visualization>
  </div>
</div>
