<div class="tab-form-container">
  <div class="tab-form">
    <i class="fas fa-search lighter me-1"></i>
    <input
      id="search-input"
      type="text"
      [(ngModel)]="searchTerm"
      placeholder="{{ placeholder }}"
      (keyup)="searchKeyEvent($event)"
      #searchInput />
    <button class="btn btn-outline-dark clear-search-button" *ngIf="searchTerm" (click)="clearClick($event)">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
  </div>
</div>
