<div class="mt-2">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <!-- Users -->
    <li ngbNavItem>
      <button ngbNavLink>Users</button>
      <ng-template ngbNavContent style="position: relative; width: 100%">
        <div *ngIf="combinedGridReady">
          <div class="summary">
            Users: {{ getFilteredCount(combinedGridOptions.api) }} / {{ combinedUsers.length }}, Size:
            {{ getFilteredTotalSize(combinedGridOptions.api) | bytes : 0 }}

            <!-- clear filters button -->
            <button
              [disabled]="!combinedGridOptions.api.isAnyFilterPresent()"
              class="btn btn-sm btn-info"
              (click)="onClearFilters(combinedGridOptions.api)">
              Clear filters
            </button>

            <!-- old guests button -->
            <button class="btn btn-sm btn-secondary" (click)="onSelectOldGuests(combinedGridOptions.api)">
              Filter old guests
            </button>

            <!-- list filter buttons -->
            <button class="btn btn-sm btn-secondary ms-4" (click)="onToggleListFilter(combinedGridOptions.api)">
              <span *ngIf="!listFilterVisible">Open</span>
              <span *ngIf="listFilterVisible">Close</span>
              filter list
            </button>
            <button class="btn btn-sm btn-secondary" (click)="onFilterListed(combinedGridOptions.api)">
              Filter listed users
            </button>

            <!-- delete button -->
            <div ngbDropdown class="delete">
              <button
                type="button"
                [disabled]="combinedUsers.length < 1"
                class="btn btn-sm btn-danger"
                id="dropdownBasic1"
                ngbDropdownToggle>
                Delete
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="onDeleteUsers(getFilteredRows(combinedGridOptions.api))">
                  Delete users
                </button>
                <button ngbDropdownItem (click)="onDeleteSessions(getFilteredRows(combinedGridOptions.api))">
                  Delete sessions
                </button>
                <button ngbDropdownItem (click)="onDeleteUsersAndSessions(getFilteredRows(combinedGridOptions.api))">
                  Delete users and sessions
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- user list filter -->
        <textarea
          [hidden]="!listFilterVisible"
          class="mb-1 form-control"
          #notesArea
          id="session-notes"
          rows="15"
          placeholder="One user per line. If line contains a comma, only use the first part."
          [(ngModel)]="listFilterText"></textarea>

        <ag-grid-angular
          *ngIf="allSessionsState.isReady(); else statusTemplate"
          style="width: 100%; height: 700px"
          class="ag-theme-alpine"
          [gridOptions]="combinedGridOptions"></ag-grid-angular>

        <div *ngIf="combinedGridReady" class="text-monospace text-small m-3">
          <div *ngFor="let user of getFilteredRows(combinedGridOptions.api)">{{ user.username }}</div>
        </div>
      </ng-template>
    </li>

    <!-- Auth only -->
    <li ngbNavItem>
      <button ngbNavLink>Auth only</button>
      <ng-template ngbNavContent>
        <div *ngIf="authOnlyGridReady">
          <div class="summary">
            Users: {{ getFilteredCount(authOnlyGridOptions.api) }} / {{ authOnlyUsers.length }}

            <!-- clear filters button -->
            <button
              [disabled]="!authOnlyGridOptions.api.isAnyFilterPresent()"
              class="btn btn-sm btn-info"
              (click)="onClearFilters(authOnlyGridOptions.api)">
              Clear filters
            </button>

            <!-- old guests button -->
            <button class="btn btn-sm btn-secondary" (click)="onSelectOldGuests(authOnlyGridOptions.api)">
              Filter old guests
            </button>

            <!-- list filter buttons -->
            <button class="btn btn-sm btn-secondary ms-4" (click)="onToggleListFilter(authOnlyGridOptions.api)">
              <span *ngIf="!listFilterVisible">Open</span>
              <span *ngIf="listFilterVisible">Close</span>
              filter list
            </button>
            <button class="btn btn-sm btn-secondary" (click)="onFilterListed(authOnlyGridOptions.api)">
              Filter listed users
            </button>

            <!-- delete button -->
            <div class="delete">
              <button
                [disabled]="authOnlyUsers.length < 1"
                class="btn btn-sm btn-danger"
                (click)="onDeleteUsers(getFilteredRows(authOnlyGridOptions.api))">
                Delete users
              </button>
            </div>
          </div>
        </div>

        <!-- user list filter -->
        <textarea
          [hidden]="!listFilterVisible"
          class="mb-1 form-control"
          #notesArea
          id="session-notes"
          rows="15"
          placeholder="One user per line. If line contains a comma, only use the first part."
          [(ngModel)]="listFilterText"></textarea>

        <ag-grid-angular
          *ngIf="allSessionsState.isReady(); else statusTemplate"
          style="width: 100%; height: 700px"
          class="ag-theme-alpine"
          [gridOptions]="authOnlyGridOptions"></ag-grid-angular>

        <div *ngIf="authOnlyGridReady" class="text-monospace text-small m-3">
          <div *ngFor="let user of getFilteredRows(authOnlyGridOptions.api)">{{ user.username }}</div>
        </div>
      </ng-template>
    </li>

    <!-- SessionDB only -->
    <li ngbNavItem>
      <button ngbNavLink>SessionDB only</button>
      <ng-template ngbNavContent>
        <div *ngIf="sessionDbOnlyGridReady">
          <div class="summary">
            Users: {{ getFilteredCount(sessionDbOnlyGridOptions.api) }} / {{ sessionDbOnlyUsers.length }}

            <!-- clear filters button -->
            <button
              [disabled]="!sessionDbOnlyGridOptions.api.isAnyFilterPresent()"
              class="btn btn-sm btn-info"
              (click)="onClearFilters(sessionDbOnlyGridOptions.api)">
              Clear filters
            </button>

            <!-- list filter buttons -->
            <button class="btn btn-sm btn-secondary ms-4" (click)="onToggleListFilter(sessionDbOnlyGridOptions.api)">
              <span *ngIf="!listFilterVisible">Open</span>
              <span *ngIf="listFilterVisible">Close</span>
              filter list
            </button>
            <button class="btn btn-sm btn-secondary" (click)="onFilterListed(sessionDbOnlyGridOptions.api)">
              Filter listed users
            </button>

            <!-- delete button -->
            <div class="delete">
              <button
                [disabled]="sessionDbOnlyUsers.length < 1"
                class="btn btn-sm btn-danger"
                (click)="onDeleteSessions(getFilteredRows(sessionDbOnlyGridOptions.api))">
                Delete sessions
              </button>
            </div>
          </div>
        </div>

        <!-- user list filter -->
        <textarea
          [hidden]="!listFilterVisible"
          class="mb-1 form-control"
          #notesArea
          id="session-notes"
          rows="15"
          placeholder="One user per line. If line contains a comma, only use the first part."
          [(ngModel)]="listFilterText"></textarea>

        <ag-grid-angular
          *ngIf="allSessionsState.isReady(); else statusTemplate"
          style="width: 100%; height: 700px"
          class="ag-theme-alpine"
          [gridOptions]="sessionDbOnlyGridOptions"></ag-grid-angular>

        <div *ngIf="sessionDbOnlyGridReady" class="text-monospace text-small m-3">
          <div *ngFor="let user of getFilteredRows(sessionDbOnlyGridOptions.api)">{{ getUsername(user.userId) }}</div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>

  <ng-template #statusTemplate>
    <ch-status [state]="allSessionsState"></ch-status>
  </ng-template>

  <ng-template #modalContent let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Sessions of {{ selectedUser }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body scrollable-parent">
      <div *ngIf="!userSessionsState.isReady()">Loading sessions...</div>
      <div *ngIf="userSessionsState.isReady()" class="scrollable">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <!-- <th scope="col">Name</th> -->
              <th scope="col">SessionId</th>
              <th scope="col">Size</th>
              <th scope="col">Datasets</th>
              <th scope="col">Jobs</th>
              <th scope="col">Inputs</th>
              <th scope="col">Parameters</th>
              <th scope="col">Phenodata entries</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of sessions">
              <!-- <th scope="row">{{ session.name }}</th> -->
              <td>{{ session.sessionId }}</td>
              <td>{{ session.size | bytes : 0 }}</td>
              <td>{{ session.datasetCount }}</td>
              <td>{{ session.jobCount }}</td>
              <td>{{ session.inputCount }}</td>
              <td>{{ session.parameterCount }}</td>
              <td>{{ session.metadataCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</div>
