<div class="container-fluid">
  <div class="row">
    <div class="col-1 menu">
      <ul class="nav nav-stacked">
        <li class="menu-li">
          <button class="menu-button" routerLink="services" routerLinkActive="active">
            <i class="fas fa-server fa-2x" aria-hidden="true"></i>
            <br />
            Services
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="clients" routerLinkActive="active">
            <i class="fas fa-laptop fa-2x" aria-hidden="true"></i>
            <br />
            Clients
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="users" routerLinkActive="active">
            <i class="fas fa-user fa-2x" aria-hidden="true"></i>
            <br />
            Users
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="storage" routerLinkActive="active">
            <i class="fas fa-database fa-2x" aria-hidden="true"></i>
            <br />
            Storage
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="jobs" routerLinkActive="active">
            <i class="fas fa-hourglass-start fa-2x" aria-hidden="true"></i>
            <br />
            Jobs
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="history" routerLinkActive="active">
            <i class="fas fa-calendar fa-2x" aria-hidden="true"></i>
            <br />
            Job Log
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="statistics" routerLinkActive="active">
            <i class="fas fa-chart-bar fa-2x" aria-hidden="true"></i>
            <br />
            Statistics
          </button>
        </li>
        <li class="menu-li">
          <button class="menu-button" routerLink="news" routerLinkActive="active">
            <i class="fas fa-message fa-2x" aria-hidden="true"></i>
            <br />
            News
          </button>
        </li>

        <li class="menu-li">
          <button class="menu-button" routerLink="maintenance" routerLinkActive="active">
            <i class="fas fa-wrench fa-2x" aria-hidden="true"></i>
            <br />
            Maintenance
          </button>
        </li>
      </ul>
    </div>
    <div class="col-11 content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
