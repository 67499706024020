<div class="row">
  <div class="col">
    <button type="button" class="btn btn-link float-end" (click)="openNewTab()">
      <span class="fas fa-external-link"></span>
      Open in new tab
    </button>
  </div>
</div>

<div *ngIf="state.isReady(); else statusTemplate" class="scrollable">
  <img [src]="src" />
</div>

<ng-template #statusTemplate>
  <ch-status [state]="state"></ch-status>
</ng-template>
