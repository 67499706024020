<div *ngIf="editable" class="mt-3 mb-3">
  <button class="btn btn-sm btn-success" (click)="onAdd()">Add</button>
</div>

<div *ngIf="state.isReady() && news != null && news.length > 0">
  <table class="table table">
    <tbody>
      <tr *ngFor="let n of news">
        <td>
          <ch-news-item
            [newsItem]="n"
            [editable]="editable"
            (edit)="onEdit($event)"
            (delete)="onDelete($event)"></ch-news-item>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="state.isReady() && (news == null || news.length < 1)">No news yet.</div>

<div *ngIf="state.isLoading()">Loading news...</div>
<div *ngIf="state.isFail()">{{ errorMessage }}</div>
