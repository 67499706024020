<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <h3 class="h3-xs">
    {{ message }}
  </h3>

  <div *ngFor="let user of users">{{ user.userId }}</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.dismiss()">Close</button>
  <button type="button" class="btn btn-sm btn-danger" (click)="activeModal.close(true)">Delete</button>
</div>
