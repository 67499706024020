<div *ngIf="ready">
  <div [ngSwitch]="phenodataState">
    <div *ngSwitchCase="PhenodataState.OWN_PHENODATA">
      <div class="phenodata-button-group mb-1">
        <button class="btn btn-sm btn-success me-1" (click)="openAddColumnModal()">
          <i class="fas fa-plus"></i>
          Add column
        </button>
        <button class="btn btn-sm btn-secondary float-end" (click)="reset()">Reset all</button>
      </div>

      <div [class.invisible]="phenodataFilled" class="alert alert-warning p-0 mb-1">
        <i class="fas fa-fw fa-exclamation-circle"></i>
        <span class="font-italic">Group column contains empty values</span>
      </div>
    </div>

    <div *ngSwitchCase="PhenodataState.INHERITED_PHENODATA">
      Using phenodata of
      <i>{{ phenodataAncestor.name }}</i>
    </div>

    <div *ngSwitchCase="PhenodataState.NO_PHENODATA">
      <i>No phenodata available</i>
    </div>

    <div *ngSwitchCase="PhenodataState.DATASET_NULL"></div>
  </div>
</div>

<!-- tableContainer must always be around or new Handsontable fails, so no ngIf for it -->
<div class="scrolling-wrapper" #horizontalScroll>
  <div id="tableContainer"></div>
</div>
