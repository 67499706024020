<table class="table">
  <thead>
    <tr>
      <th scope="col">Username</th>
      <th scope="col">Connected</th>
      <th scope="col">Address</th>
      <th scope="col">Proxied address</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <th scope="row">{{ user.username }}</th>
      <td>{{ user.created | localDate }}</td>
      <td>{{ user.address }}</td>
      <td>{{ user["X-Forwarded-For"] }}</td>
    </tr>
  </tbody>
</table>
