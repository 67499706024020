<div *ngIf="ready" class="">
  <!-- <h1 class="h1-xs">{{validatedTool.tool.name.displayName}}
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
  </h1> -->

  <!-- warning title -->
  <!-- <div class="alert alert-warning text-center p-1 mb-0" [class.invisible]="!showWarning">
    <i class="fas fa-fw fa-exclamation-circle"></i> {{ warningText }}
  </div> -->

  <!-- parameters -->
  <div class="row">
    <div class="col">
      <h2 class="h2-xs mt-1 mb-2">Parameters</h2>
    </div>

    <div class="col">
      <button
        *ngIf="isResetAllVisible()"
        class="btn btn-sm btn-outline-secondary me-1 float-end"
        (click)="resetAll()"
        title="Reset all">
        <span class="fas fa-fw fa-undo text-primary" aria-hidden="true"></span>
        Reset All
      </button>
      <button
        *ngIf="!isResetAllVisible()"
        class="btn btn-sm btn-outline-secondary me-1 float-end"
        (click)="resetAll()"
        title="Reset all"
        [disabled]="true">
        <span class="fas fa-fw fa-undo" aria-hidden="true"></span>
        Reset All
      </button>
    </div>
  </div>

  <form name="paramForm" *ngIf="validatedTool.tool">
    <div *ngIf="validatedTool.tool.parameters.length === 0" class="font-italic text-smaller">No parameters</div>

    <table class="table table-sm" *ngIf="validatedTool.tool.parameters.length > 0">
      <tbody>
        <tr *ngFor="let parameter of validatedTool.tool.parameters; let i = index">
          <td style="width: 60%">
            <!-- parameter name -->
            {{ getDisplayName(parameter) }}
            <!-- <span class="text-sm text-muted" *ngIf="parameter.optional">
              <i>(optional)</i>
            </span> -->

            <!-- parameter description -->
            <div class="text-sm text-muted">{{ parameter.description }}</div>
          </td>

          <!-- parameter value -->

          <td>
            <!-- selection parameter -->
            <select
              *ngIf="toolService.isSelectionParameter(parameter)"
              class="w-100"
              name="parameter_{{ i }}"
              [(ngModel)]="parameter.value"
              (ngModelChange)="onParametersChanged()">
              <option *ngFor="let option of parameter.selectionOptions" value="{{ option.id }}">
                {{ option.displayName || option.id }}
              </option>
            </select>

            <!-- number -->
            <input
              *ngIf="toolService.isNumberParameter(parameter)"
              class="w-100"
              type="number"
              [(ngModel)]="parameter.value"
              name="parameter_{{ i }}"
              min="{{ parameter.from }}"
              max="{{ parameter.to }}"
              step="{{ toolService.getStepSize(parameter) }}"
              required="{{ !parameter.optional }}"
              (ngModelChange)="onParametersChanged()" />

            <!-- string -->
            <input
              *ngIf="toolService.isStringParameter(parameter)"
              type="text"
              class="w-100"
              name="parameter_{{ i }}"
              [(ngModel)]="parameter.value"
              required="{{ !parameter.optional }}"
              (ngModelChange)="onParametersChanged()" />

            <!-- validation message -->
            <div
              *ngIf="!validatedTool.parametersValidationResults.get(parameter.name.id).valid"
              class="alert alert-warning p-1 mb-0 mt-1 text-sm">
              {{ validatedTool.parametersValidationResults.get(parameter.name.id).message }}
            </div>
          </td>

          <!-- buttons -->
          <td>
            <!-- reset to default -->
            <button
              type="button"
              class="btn btn-sm btn-outline-dark btn-no-border float-end"
              [class.invisible]="!isResetVisible(parameter)"
              (click)="reset(parameter, $event)"
              title="Reset to default">
              <i class="fas fa-undo text-primary"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
