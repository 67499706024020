<table class="table table-sm text-sm">
  <tbody>
    <tr *ngFor="let param of parameterListForView; let i = index">
      <ng-template [ngIf]="i < limit">
        <td style="width: 60%" [ngClass]="{ 'no-top-border': i === 0, 'no-horizontal-padding': historyMode }">
          {{ param.displayName }}
        </td>
        <td
          [ngStyle]="{ 'font-weight': historyMode || isDefaultValueMap.get(param) ? 'normal' : 'bold' }"
          [ngClass]="{ 'no-top-border': i === 0 }">
          {{ param.value }}
        </td>
      </ng-template>
    </tr>
    <tr *ngIf="!noLimit && parameterListForView.length > defaultLimit">
      <td colspan="2">
        <ch-link-button (click)="toggleParameterList()">{{ buttonText }}</ch-link-button>
      </td>
    </tr>
  </tbody>
</table>
