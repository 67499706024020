<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div [innerHtml]="message"></div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="close()">Close</button>
</div>
