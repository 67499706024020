<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3">
  <div class="form-group form-row">
    <div class="col-sm-2">
      <label>Year</label>
      <select formControlName="year" class="form-control form-control-sm">
        <option class="text-sm" *ngFor="let year of years" [value]="year">
          {{ year }}
        </option>
      </select>
    </div>

    <div class="col-sm-2">
      <label>Module</label>
      <select formControlName="module" class="form-control form-control-sm">
        <option class="text-sm" *ngFor="let module of modules" [value]="module">
          {{ module }}
        </option>
      </select>
    </div>

    <div class="col-sm-8">
      <label>Ignore Users</label>
      <input formControlName="ignoreUsers" class="form-control form-control-sm" />
    </div>
  </div>

  <div class="form-group form-row">
    <div class="col-sm-1">
      <button class="btn btn-sm btn-info form-control form-control-sm" type="submit">Search</button>
    </div>
  </div>
</form>

<div *ngIf="state.isReady(); else statusTemplate">
  <div class="row">
    <div *ngIf="userCount != null" class="col-sm-3 text-sm">
      <div>Users: {{ userCount }}</div>
      <div>Jobs: {{ jobCount }}</div>
    </div>
  </div>
</div>

<ng-template #statusTemplate>
  <ch-status [state]="state"></ch-status>
</ng-template>
