<div class="modal-header">
  <h4 class="modal-title">{{ validatedTool.tool.name.displayName }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <ch-tool-parameters [validatedTool]="validatedTool" (parametersChanged)="onParametersChanged()"></ch-tool-parameters>
  <ch-tool-inputs
    [validatedTool]="validatedTool"
    [sessionData]="sessionData"
    (updateBindings)="setBindings($event)"></ch-tool-inputs>
  <ch-run-options [validatedTool]="validatedTool"></ch-run-options>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.close()">Close</button>
</div>
