<!-- context dropdown -->
<div ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-sm btn-success"
    id="addFileDropdownMenuButton"
    ngbDropdownToggle
    aria-haspopup="true"
    aria-expanded="false"
    title="Actions">
    <i class="fas fa-upload" aria-hidden="true"></i>
    Add file
  </button>
  <div ngbDropdownMenu aria-labelledby="addFileDropdownMenuButton">
    <button #uploadFilesButton ngbDropdownItem>Upload file(s)</button>
    <button #uploadFolderButton ngbDropdownItem>Upload folder</button>
    <div class="dropdown-divider"></div>
    <button ngbDropdownItem (click)="downloadFromUrl()">Download from URL</button>
  </div>
</div>
